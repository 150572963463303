
.abt{
  padding-top: 8rem;
  padding-bottom: 8rem;
  background: rgba(0, 0, 0, 0.3);
  transform: translateZ(50px);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
}

.abt-jumbotron {
    background-color: transparent;
    padding: 0.1rem 2rem;
  }
  .abt-jumbotron h1 {
    font-size: 4rem;
    font-weight: bold;
    text-shadow: 2px 2px #000;
    margin-bottom: 2rem;
  }
  .abt-jumbotron p {
    font-size: 1.5rem;
    font-weight: bold;
    text-shadow: 1px 1px #000;
    margin-bottom: 2rem;
  }

  .abt-white-box{
    background-color: black;
    background: url('../Resources/image.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .paragraph {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  }
  
  .paragraph h2,
  .abt h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 15px;
  }
  
  .paragraph p {
    text-align: justify;
    font-size: 1.2rem;
    line-height: 1.5;
    color: whitesmoke;
  }
  
  .abt p {
    text-align: justify;
    font-size: 1.2rem;
    line-height: 1.5;
    color: white;
  }


  @media only screen and (min-width: 1300px) {
    .paragraph {
        max-width: 1200px;
        
    }
  }

  @media only screen and  (max-width:1300px) {
    .paragraph p {
      font-size: 1rem;
    }

    .abt{
      padding-top: 6rem;
      padding-bottom: 6rem;
      
    }
  }