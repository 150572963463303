.read-more-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.6s ease-out;
  }
  
  .read-more-content.expanded {
    max-height: 500px;
  }
  
  .paraCard{
    /* padding-top: 5rem;
    padding-bottom: 3rem; */
    background: rgba(84, 84, 84, 0.3);
    transform: translateZ(50px);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}