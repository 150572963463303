
.addvertise {
  height: 30vh;
  background: url('../Resources/123.png') no-repeat center center / cover;
}

.service-white-box {
  padding-top: 9rem;
  background-color: black;
  height: 93vh;
}

.service-jumbotron {
  background-color: transparent;
  padding: 0.1rem 2rem;
}

.service-jumbotron h1 {
  font-size: 3.6rem;
  font-weight: bolder;
  text-shadow: 2px 2px #000;
  margin-bottom: 2rem;
}

.service-jumbotron p {
  font-size: 1.3rem;
  text-shadow: 1px 1px #000;
  margin-bottom: 2rem;
}

.service-card {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-out;
  height: 310px;
}

.service-card:hover {
  transform: translateY(-10px);
}

.service-card .card-text {
  padding: 20px;
}

.view-more {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  text-decoration: none;
  transition: opacity 0.3s ease-out;
  opacity: 0;
}

.service-card:hover .view-more {
  opacity: 1;
}

@media (max-width: 767px) {
  .service-card {
    height: 150px;
  }
}

@media only screen and (min-width: 1002px) and (max-width: 1100px) {
  .service-jumbotron h1 {
    font-size: 2.6rem;
  }
}

.card-overlay {
  position: absolute;
  bottom: 10;
  left: 0;
  right: 0;
  padding: 1rem;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(100%);
  /* background: rgba(0, 0, 0, 0.8); */
  /* background: rgba(49, 49, 49, 0.8); */
}

.service-card:hover .card-overlay {
  opacity: 1;
  transform: translateY(0%);
}
 .hello:hover{
  filter: grayscale(100%);
 }