  
  .contact{
    padding-top: 5rem;
    padding-bottom: 3rem;
    /* background: rgba(84, 84, 84, 0.3); */
    background: rgba(0, 0, 0, 0.3);
    transform: translateZ(50px);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .contact-box {
      background-color: transparent;
      padding: 0.1rem 2rem;
    }

  /* .contact{ */
    /* border: 1px solid red; */
    /* padding-top: 6rem;
    padding-bottom: 6rem;
    margin-left: 1rem; */
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* } */
  
  .form-label {
    font-weight: bold;
  }
  
  .form-control:focus {
    border-color: #007bff;
    box-shadow: none;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:hover {
background-color: #0069d9;
border-color: #0062cc;
}

.btn-primary:focus {
box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

label{
    /* font-family: Arial, Helvetica, sans-serif; */
    font-weight: bold;
    margin-top: 0.6rem;
  }
  
  @media only screen and (max-width:1300px){
      .contact p {
        font-size: 1.1rem;
      }
  }