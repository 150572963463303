.item-center {
    display: flex;
    justify-content: center; 
    align-items: center; 
  }
  
  .image-container {
    max-width: 100%; /* Set the maximum width of the image container */
    height: auto; /* Maintain the aspect ratio of the image */
  }
  
  .image-container img {
    max-width: 100%; /* Set the maximum width of the image to fill the container */
    height: auto; /* Maintain the aspect ratio of the image */
  }
  
  .list-unstyled a{
    color: grey;
    font-weight: lighter;
    font-size: medium;
  }