body{
  font-weight: bolder;
}

.navbar-nav > li > a {
  border-radius: 50px;
}
.navbar-nav > li {
  margin: 2px 2px 2px 2px;
}
.navbar-nav > li > a:hover {
    transition: background-color 0.3s ease;
    /* text-decoration: underline; */
}
.navbar-nav > li:hover {
  transition: background-color 0.3s ease;
  border-bottom: 2px solid white;
}

.blur-transparent {
  z-index: 99999;
  /* backdrop-filter: blur(5px); */
  /* -webkit-backdrop-filter: blur(5px); For Safari */
  /* background-color: rgba(255, 255, 255, 0.5); */
}

  @media only screen and (min-width: 768px) {
    .container-fluid {
      margin-left: 0.6rem;
      margin-right: 0.6rem;
    }
  }

  @media only screen and (min-width: 842px) {
    .container-fluid {
      margin-left: 0rem;
      margin-right: 3rem;
    }
  }