body {
    /* background-color: #fed502; */
    background-color: black;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
  }

  .home{
    padding-top: 5rem;
    padding-bottom: 3rem;
    background: rgba(84, 84, 84, 0.3);
    transform: translateZ(50px);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .home-box {
      background-color: transparent;
      padding: 0.1rem 2rem;
    }
    .home-box h1 {
      font-size: 4rem;
      font-weight: bolder;
      text-shadow: 2px 2px #000;
      margin-bottom: 2rem;
    }
    .home-box p {
      font-size: 1.3rem;
      font-weight: bold;
      text-shadow: 1px 1px #000;
      margin-bottom: 2rem;
    }
  
    .back{
      background-color: black;
      background: url('../Resources/Aboutbg.webp');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    /* .growth-white-box{
      background-color: black;
      background: url('../Resources/growthBlur.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .exp-white-box{
      background-color: black;
      background: url('../Resources/expBlur.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    } */


  .btn {
    background-color: #000;
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: 0;
    padding: 1rem 2rem;
    margin-top: 1rem;
    border: none;
  }
  .btn:hover {
    background-color: #fff;
    color: #000;
    border: 2px solid #000;
  }


  .card {
    animation: fade-in 1s ease-in-out;
  }
  
  @keyframes fade-in {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .carousel-item img {
    max-height: 300px;
    object-fit: cover;
  }
  .box {
    background-color: transparent;
    /* padding: 4rem 2rem; */
  }
  .box h1 {
    font-size: 4rem;
    font-weight: bold;
    text-shadow: 2px 2px #000;
    margin-bottom: 2rem;
  }
  .box p {
    font-size: 1.5rem;
    font-weight: bold;
    text-shadow: 1px 1px #000;
    margin-bottom: 2rem;
  }

video{
  object-fit: cover;
  width: 100%;
  height: 45rem;
  opacity: 0.7;
  margin-left: 1px;
}

/* .vc{
  position: absolute;
  top: 38%;
  left: 4%;
} */
  .carousel-caption {
    background-color: rgba(0,0,0,0.5);
    padding: 10px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  .carousel-caption h5, .carousel-caption p {
    color: #fff;
  }
  
  .card {
    border: none;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  }
  
  .card-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .card-text {
    font-size: 1rem;
    color: #666;
  }
  
  .white-box{
    background-color: black;
  }
  .carousel {
    width: 80%;
    margin: 0 auto;
  }

  .form-group label {
    font-weight: bold;
  }
  .form-group input[type="text"], .form-group input[type="email"], .form-group textarea {
    border-radius: 0;
    border: 1px solid #ced4da;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
  }
  .form-group input[type="text"]:focus, .form-group input[type="email"]:focus, .form-group textarea:focus {
    border-color: #4d90fe;
    box-shadow: 0 0 0 0.25rem rgba(77, 144, 254, 0.25);
  }

  .partner-section {
    padding: 3rem 0;
  }
  .partner-section h2 {
    text-align: center;
    margin-bottom: 2rem;
  }
  .partner-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .partner-logo {
    width: 150px;
    height: 100px;
    margin: 1rem;
    background-color: #fff;
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .partner-logo img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  @media only screen and (max-width: 1300px) {
    body {
      font-size: 1rem;
    }
    .home p,
    .box p {
      font-size: 1.2rem;
      font-weight: bold;
      text-shadow: 1px 1px #000;
      margin-bottom: 0rem;
    }

    .box h1 {
      font-size: 3rem;
      font-weight: bold;
      text-shadow: 2px 2px #000;
      margin-bottom: 1rem;
    }
  }

  @media only screen and (max-width: 600px) {
    video{
      height: 48rem;
      margin-left: 5px;

    }
  }
