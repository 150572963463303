@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body{
  font-family: 'Montserrat', sans-serif;

}
/* body::-webkit-scrollbar {
  width: 1em;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
} */

.whatsapp-container a {
  text-decoration: none;
}

.whatsapp-container a .fa-whatsapp {
  color: #25D366;
  font-size: 3.5rem;
}

@media (min-width: 992px) {
  .whatsapp-container {
    justify-content: start;
  }
  
  .whatsapp-container a {
    padding: 15px;
  }
  
  .whatsapp-container a .fa-whatsapp {
    font-size: 4rem;
  }
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background-color: darkgrey;
}

body::-webkit-scrollbar-thumb {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  box-shadow: inset 0 0 6px black;
}

body {
  scrollbar-width: thin;
}

body {
  scrollbar-color: #6969dd #e0e0e0;
  scrollbar-width: thin;
}

.pagination button {
  z-index: 9999;
}